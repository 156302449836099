import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  //smallMarginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const InteroperablePortalePage = () => {
  return (
    <Layout pageTitle="InteroperablePortale" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>Artikel & Interview - März 2024</p>
                <h1>
                  Interoperable Portale gestalten medizinische Versorgung neu
                </h1>

                <p className="text-medium">
                  Das Patientenportal ist ein wichtiger Baustein in der
                  zukünftigen Versorgung und Interaktion mit Patient:
                  Patient:innen. Doch isoliert betrachtet adressiert es nur
                  einen Teil der Herausforderungen entlang des Behandlungspfads.{" "}
                </p>

                <p>
                  Es bedarf einer Lösung, die je nach Nutzergruppe und
                  involvierten Personen spezifische Sichten und Funktionen zur
                  Verfügung stellt. the i-engineers nennt dies eine integrierte
                  Portalewelt von der Zuweisung bis zur Nachsorge. Volker
                  Sobieroy, Geschäftsführer der the i-engineers GmbH, geht in
                  seinem Interview auf die Bausteine und Vorteile der
                  integrierten und interoperablen Portalewelt ein. (...)
                </p>
                <p>
                  Für alle, die erfahren möchten, was die Bausteine und Vorteile
                  der integrierten und interoperablen Portalwelt ist empfehlen
                  wir das Geschäftsführer Interview und den Marktübersicht
                  Artikel (PDF) aus dem KH-IT Journal:
                </p>

                <div className={buttonGroup}>
                  <a
                    href="https://www.tie.ch/downloads/TIE_GF_KH-IT_Journal.pdf"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Zum vollständigen Geschäftsführer Interview{" "}
                  </a>{" "}
                </div>
                <div className={buttonGroup}>
                  <a
                    href="https://www.tie.ch/downloads/TIE_patientenportal_KH-IT_Journal.pdf"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Zum vollständigen Marktübersicht Artikel
                  </a>{" "}
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default InteroperablePortalePage;
